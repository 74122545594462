@keyframes loading-shimmer {
  0%{
    background-position: -999px 0;
  }
  100%{
    background-position: 999px 0;
  }
}

@mixin sgme-animated-loading-skeleton {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loading-shimmer;
  animation-timing-function: linear;
  background: #afa2a2;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 100%;
  height: auto;
  position: relative;
  pointer-events: none;
  cursor: default;
}

.sgme-animated-loading-skeleton {
  @include sgme-animated-loading-skeleton;
  animation-duration: 1.25s;
}
.sgme-animated-loading-skeleton-fast {
  @include sgme-animated-loading-skeleton;
  animation-duration: 0.75s;
}
.sgme-animated-loading-skeleton-slow {
  @include sgme-animated-loading-skeleton;
  animation-duration: 1.75s;
}

.sgme-animated-loading-skeleton-rounded {
  @include sgme-animated-loading-skeleton;
  animation-duration: 1.25s;
  border-radius: .25rem;
}
.sgme-animated-loading-skeleton-fast-rounded {
  @include sgme-animated-loading-skeleton;
  animation-duration: 0.75s;
  border-radius: .25rem;
}
.sgme-animated-loading-skeleton-slow-rounded {
  @include sgme-animated-loading-skeleton;
  animation-duration: 1.75s;
  border-radius: .25rem;
}

.sgme-animated-loading-skeleton-rounded-sm {
  @include sgme-animated-loading-skeleton;
  animation-duration: 1.25s;
  border-radius: .2rem;
}
.sgme-animated-loading-skeleton-fast-rounded-sm {
  @include sgme-animated-loading-skeleton;
  animation-duration: 0.75s;
  border-radius: .2rem;
}
.sgme-animated-loading-skeleton-slow-rounded-sm {
  @include sgme-animated-loading-skeleton;
  animation-duration: 1.75s;
  border-radius: .2rem;
}

.sgme-animated-loading-skeleton-rounded-lg {
  @include sgme-animated-loading-skeleton;
  animation-duration: 1.25s;
  border-radius: .3rem;
}
.sgme-animated-loading-skeleton-fast-rounded-lg {
  @include sgme-animated-loading-skeleton;
  animation-duration: 0.75s;
  border-radius: .3rem;
}
.sgme-animated-loading-skeleton-slow-rounded-lg {
  @include sgme-animated-loading-skeleton;
  animation-duration: 1.75s;
  border-radius: .3rem;
}
