$directions: 'x', 'y';
$values: 'auto', 'hidden';

@each $direction in $directions {
  @each $value in $values {
    .overflow-#{$direction}-#{$value} {
      overflow-#{$direction}: #{$value} !important;
    }
  }
}
